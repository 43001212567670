import moment from 'moment';

const PolicyModality = {
  monthly: 'Mensal recorrente',
  semi_annual: 'Semestral',
  annual: 'Anual',
}
export const translatePolicyModality = (modality) => PolicyModality[modality];

export const addMonthsByModality = (date, modality) =>
  moment(date).add({ monthly: 1, semi_annual: 6, annual: 12 }[modality], 'months');

export const FidelityDiscountLabelByModality = {
  semi_annual: "6ª",
  annual: "12ª",
}