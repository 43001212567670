/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import api from 'api';
import { Stepper } from 'components';
import { Centralizer, NewQuotationContainer, Title } from './style';
import { MdChevronLeft, MdRestartAlt } from 'react-icons/md';
import { IconButton, Tooltip } from '@material-ui/core';
import { PlateStep, VehicleModelStep, AddressStep, CoverageStep, ProposalStep, EmissionStep, ClientStep } from './Steps';
import { toast } from 'react-toastify';
import { formatBirthdate } from 'utils/birthDate';
import { API_TOKEN } from 'utils/token';

const NewQuotation = ({ history, location }) => {
  const quotation = location?.state?.quotation;
  const [currentStep, setCurrentStep] = useState(1);
  const [quotationData, setQuotationData] = useState({
    comission: 0,
    discount_new: 0,
    raise_price: 0,
    mainDriver: { isOwner: false },
    vehicle: {
      zero_km: false,
      auction: false,
      gnv: false,
      vehicle_already_insured: false,
      under25: false,
    },
  });
  const [quotationResponse, setQuotationResponse] = useState({});
  const [proposalResponse, setProposalResponse] = useState({});
  const [loadingQuotation, setLoadingQuotation] = useState(false);
  const [loadingProposal, setLoadingProposal] = useState(false);
  const [vehicleAccepted, setvehicleAccepted] = useState(false);
  const [sliderHandle, setSliderHandle] = useState({ handle: false, discount_percent: 0, grievance_percent: 0 });
  const [percentCommission, setPercentCommission] = useState(0);
  const [percentDiscount, setPercentDiscount] = useState(0);
  const [showAuctionModal, setShowAuctionModal] = useState(false);
  const [loadingVerifyVehicleAuction, setLoadingVerifyVehicleAuction] = useState(false);

  const [dataCoverage, setDataCoverage] = useState({});
  const host = window.location.hostname;
  const isOceanica = host.split('.')[0] === 'oceanica';

  const loadedQuotation = location?.state?.quotation;

  const steps = [
    { id: 1, name: 'Dados do proprietário' },
    { id: 2, name: 'Selecionar endereço' },
    { id: 3, name: 'Selecionar veículo' },
    { id: 4, name: 'Dados do veículo' },
    { id: 5, name: 'Coberturas' },
    { id: 6, name: 'Emitir proposta' },
    { id: 7, name: 'Emissão' },
  ];

  const nextStep = () => {
    if (currentStep === steps.length - 1) {
      setCurrentStep(steps.length);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep === 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const goToStep = (step) => {
    setCurrentStep(step);
  };

  const handleQuotation = async (receivedCoverages) => {
    const products =
      receivedCoverages?.length > 0 ? receivedCoverages?.map((i) => i.id) : quotationData?.products?.map((i) => i.id);
    if (quotationData.id_basic_coverage) {
      setLoadingQuotation(true);

      try {
        const response = await api({
          method: 'post',
          url: 'quotation/new-quotation',
          data: {
            ...quotationData,
            products,
            cep: quotationData?.address?.zip_code,
            value: quotationData?.vehicle?.value,
            year_model: quotationData?.vehicle?.year_model,
            year_manufacture: quotationData?.vehicle?.build_year,
            id_use: quotationData?.vehicle?.id_vehicle_use,
            brand: quotationData?.vehicle?.id_brand,
            fipe_code: quotationData?.vehicle?.fipe_code,
            id_basic_coverage: Number(quotationData.id_basic_coverage),
            from_auction: quotationData?.vehicle?.auction,
            id_support_coverage: Number(quotationData.id_support_coverage),
            id_deductible_coverage: Number(quotationData.id_deductible_coverage),
            id_replacement_car_coverage: Number(quotationData.id_replacement_car_coverage),
            id_glass_coverage: Number(quotationData.id_glass_coverage),
            id_fipe_lmi_coverage: Number(quotationData.id_fipe_lmi_coverage),
            has_gnv: quotationData?.vehicle?.gnv,
            zero_km: quotationData?.vehicle?.zero_km,
            eletric_hybridValue: quotationData?.vehicle?.eletric_hybridValue || false,
            some_driver_18_25_years_old: quotationData?.vehicle?.under25,
            optional_coverages: quotationData?.optionalCoverages
              ? Object.values(quotationData?.optionalCoverages).filter((value) => Number.isInteger(value))
              : [],
            discount_percent: quotationData?.discount_percent,
            grievance_percent: quotationData?.grievance_percent,
            policy_modality: quotationData?.policy_modality,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: API_TOKEN,
          },
        });
        setQuotationResponse(response?.data);
        setLoadingQuotation(false);
      } catch (e) {
        console.log(e);
        setLoadingQuotation(false);
      }
    }
  };

  const restartQuotation = () => {
    setQuotationData({
      comission: 0,
      discount_new: 0,
      raise_price: 0,
      mainDriver: { isOwner: false },
      vehicle: {
        zero_km: false,
        auction: false,
        gnv: false,
        vehicle_already_insured: false,
        under25: false,
      },
    });
    setProposalResponse({});
    setCurrentStep(1);
  };
  const getQuotation = async (id) => {
    try {
      const quotation = await api({
        method: 'get',
        url: `/quotation/proposal/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: API_TOKEN,
        },
      });
      const response = quotation.data;

      setQuotationData({
        hasQuotation: true,
        quotationProposalData: {
          quotationProposalId: id,
          leadId: response.TB_LEAD_QUOTATION.id,
          addressId: response?.TB_ADDRESS?.id,
          vehicleId: response?.TB_VEHICLE?.id,
          under25: response?.some_driver_18_25_years_old,
        },
        client: {
          name: response.TB_LEAD_QUOTATION.name,
          document: response.TB_LEAD_QUOTATION.document,
          phone: response.TB_LEAD_QUOTATION.phone,
          email: response.TB_LEAD_QUOTATION.email,
        },
        address: {
          zip_code: response?.TB_ADDRESS?.zip_code,
          street: response?.TB_ADDRESS?.street,
          number: response?.TB_ADDRESS?.number,
          complement: response?.TB_ADDRESS?.complement,
          district: response?.TB_ADDRESS?.district,
          city: response?.TB_ADDRESS?.city,
          state: response?.TB_ADDRESS?.state,
        },
        vehicle: {
          plate: response?.TB_VEHICLE?.plate,
          chassi: response?.TB_VEHICLE?.chassi,
          zero_km: response?.TB_VEHICLE?.zero_km,
          remarked: response?.TB_VEHICLE?.remarked,
          auction: response?.TB_VEHICLE?.auction === 0 ? false : true,
          gnv: response?.TB_VEHICLE?.gnv,
          vehicle_already_insured: response?.TB_VEHICLE?.vehicle_already_insured === 0 ? false : true,
          id_type: response?.TB_VEHICLE?.id_vehicle_type
            ? response?.TB_VEHICLE?.id_vehicle_type === 4
              ? 7
              : response?.TB_VEHICLE?.id_vehicle_type
            : undefined,
          id_brand: response?.TB_VEHICLE?.id_brand,
          model: response?.TB_VEHICLE?.TB_CONSTANT_VEHICLE_MODELS?.name,
          id_model: response?.TB_VEHICLE?.id_model,
          year_model: response?.TB_VEHICLE?.year_model,
          build_year: response?.TB_VEHICLE?.manufacture_year,
          value: response?.TB_VEHICLE?.value,
          id_fuel: response?.TB_VEHICLE?.id_fuel,
          fipe_code: response?.TB_VEHICLE?.fipe_code,
          id_color: response?.TB_VEHICLE?.id_color,
          id_vehicle_use: response?.TB_VEHICLE?.id_vehicle_use,
        },
      });
      const optionals = response?.TB_PRICING_OPTIONAL_COVERAGE_TB_QUOTATION_PROPOSAL.map((i) => {
        return i.id_pricing_optional_coverage;
      });

      const coverageData = {
        id_basic_coverage: response?.TB_PRICING_BASIC_COVERAGE_TB_QUOTATION_PROPOSAL[0]?.id_pricing_basic_coverage,
        id_support_coverage: response?.TB_PRICING_SUPPORT_TB_QUOTATION_PROPOSAL[0]?.id_pricing_support,
        id_replacement_car_coverage: response?.TB_PRICING_REPLACEMENT_CAR_TB_QUOTATION_PROPOSAL[0]?.id_pricing_replacement_car,
        id_glass_coverage: response?.TB_PRICING_GLASS_TB_QUOTATION_PROPOSAL[0]?.id_pricing_glass,
        id_fipe_lmi_coverage: response?.TB_PRICING_FIPE_LMI_TB_QUOTATION_PROPOSAL[0]?.id_pricing_fipe_lmi,
        id_deductible_coverage: response?.TB_PRICING_DEDUCTIBLE_TB_QUOTATION_PROPOSAL[0]?.id_pricing_deductible,
        optionals,
        discount: response.discount_percent,
        commission: response.grievance_percent,
      };
      const filteredObject = Object.fromEntries(Object.entries(coverageData).filter(([key, value]) => value !== undefined));

      setDataCoverage(filteredObject);
      let vehicleAccepted = false;
      // if (response?.TB_VEHICLE?.fipe_code) {
      //   const data = await verifyVehicle({ vehicle: response?.TB_VEHICLE });
      //   vehicleAccepted = data.data;
      // }

      if (response?.TB_VEHICLE?.value) {
        return setCurrentStep(5);
      }
      if (!response?.TB_ADDRESS?.zip_code) {
        return setCurrentStep(2);
      }
      if (!response?.TB_VEHICLE?.plate && !response?.TB_VEHICLE?.chassi) {
        return setCurrentStep(3);
      }
      return setCurrentStep(5);
    } catch (error) {
      console.log(error);
      return toast.error('Erro ao buscar cotação', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const verifyBlacklist = async (type, value) => {
    try {
      const response = await api({
        method: 'post',
        url: '/quotation/verify-blacklist',
        data: { type, value },
        headers: {
          'Content-Type': 'application/json',
          Authorization: API_TOKEN,
        },
      });
      return response.data.hasBlock;
    } catch (error) {
      console.error('Error verifying blacklist:', error);
      return false;
    }
  };

  const verifyCep = async (cep) => {
    try {
      const response = await api({
        method: 'post',
        url: '/quotation/verify-cep',
        data: { cep: cep.replace(/[^\d]/g, '') },
        headers: {
          'Content-Type': 'application/json',
          Authorization: API_TOKEN,
        },
      });
      return response.data.hasBlock;
    } catch (error) {
      console.error('Erro ao verificar o CEP:', error);
      return false;
    }
  };

  const toastError = (message) => {
    return toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  };

  const handleProposal = async () => {
    setLoadingProposal(true);
    const isCpf = quotationData?.client?.document?.length <= 14;

    const requestData = {
      id_vehicle: quotationData.quotationProposalData.vehicleId,
      id_address: quotationData.quotationProposalData.addressId,
      id_quotation_proposal: quotationData.quotationProposalData.quotationProposalId,
      billingType:
        quotationData.payment?.pay_method === '1' ? 'credit_card' : quotationData.payment?.pay_method === '2' ? 'pix' : 'billet',
      creditCard: {
        holderName: quotationData?.payment?.name,
        number: quotationData?.payment?.number?.replace(/\D/g, ''),
        expiryMonth: quotationData?.payment?.expiry?.split('/')[0],
        expiryYear: quotationData.payment?.pay_method === '1' ? '20' + quotationData?.payment?.expiry?.split('/')[1] : undefined,
        ccv: quotationData?.payment?.cvc,
      },
      client: {
        name: quotationData?.client?.name?.toUpperCase(),
        social_name: quotationData?.client?.social_name?.toUpperCase(),
        cpf: quotationData?.client?.document?.replaceAll('-', '').replaceAll('.', '').replaceAll('/', ''),
        phone: quotationData?.client?.phone?.replace(/\D/g, ''),
        email: quotationData?.client?.email,
        gender: isCpf ? quotationData?.client?.gender : '',
        birthdate: isCpf ? formatBirthdate(quotationData?.client?.birthdate) : '',
      },
      mainDriver: {
        name: quotationData?.mainDriver?.isOwner
          ? quotationData?.client?.name?.toUpperCase()
          : quotationData?.mainDriver?.name?.toUpperCase(),
        cpf: quotationData?.mainDriver?.isOwner
          ? quotationData?.client?.document?.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '')
          : quotationData?.mainDriver?.document?.replaceAll('-', '').replaceAll('.', '').replaceAll('/', ''),
        birthdate: isCpf
          ? quotationData?.mainDriver?.isOwner
            ? formatBirthdate(quotationData?.client?.birthdate)
            : formatBirthdate(quotationData?.mainDriver?.birthdate)
          : '',
      },
    };

    const formData = new FormData();
    formData.append('proposalData', JSON.stringify(requestData));
    formData.append('vehicleInvoice', quotationData?.vehicleInvoice);
    formData.append('vehicleDocument', quotationData?.vehicleDocument);

    try {
      try {
        const updateLead = await api({
          method: 'put',
          url: `/quotation/lead-quotation/${quotationData?.quotationProposalData?.leadId}`,
          data: {
            document: quotationData?.client?.document?.replace(/[^\d]/g, ''),
            email: quotationData?.client?.email,
            phone: quotationData?.client?.phone?.replace(/[^\d]/g, ''),
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: API_TOKEN,
          },
        });

        setQuotationData({
          ...quotationData,
          client: { ...quotationData.client, document: updateLead?.document, email: updateLead?.email, phone: updateLead?.phone },
        });
      } catch (error) {
        return toastError(error.message);
      }

      const hasBlockDocument = await verifyBlacklist('document', quotationData?.client?.document?.replace(/[^\d]/g, ''));
      const hasBlockPhone = await verifyBlacklist('phone', quotationData?.client?.phone?.replace(/[^\d]/g, ''));
      const hasBlockEmail = await verifyBlacklist('email', quotationData?.client?.email);
      const hasBlockCep = await verifyCep(quotationData?.address?.zip_code);
      if (quotationData?.vehicle?.plate) {
        const hasBlackListPlate = await verifyBlacklist('plate', quotationData?.vehicle?.plate?.replace(/[^a-zA-Z0-9]+/g, ''));
        if (hasBlackListPlate) return toastError('Veículo Fora da Política de Aceitação');
      }

      if (hasBlockDocument) return toastError('Veículo Fora da Política de Aceitação');
      if (hasBlockPhone) return toastError('Veículo Fora da Política de Aceitação');
      if (hasBlockEmail) return toastError('Veículo Fora da Política de Aceitação');
      if (hasBlockCep) return toastError('Veículo Fora da Política de Aceitação');

      try {
        const idVehicle = quotationData.quotationProposalData.vehicleId;
        const someDriver = Boolean(quotationData?.vehicle?.some_driver_18_25_years_old);
        const idColor = Number(quotationData?.vehicle?.id_color);
        const vehicleAlreadyInsured = Boolean(quotationData?.vehicle?.vehicle_already_insured);
        const chassi = quotationData?.vehicle?.chassi;

        const updateVehicle = await api({
          method: 'patch',
          url: `/quotation/vehicle/${quotationData.quotationProposalData.quotationProposalId}`,
          data: {
            id_vehicle: idVehicle,
            some_driver_18_25_years_old: someDriver,
            id_color: idColor,
            vehicle_already_insured: vehicleAlreadyInsured,
            chassi,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: API_TOKEN,
          },
        });
        setQuotationData({
          ...quotationData,
          vehicle: {
            ...quotationData.vehicle,
            id_color: updateVehicle?.id_color,
            some_driver_18_25_years_old: updateVehicle.some_driver_18_25_years_old,
            vehicle_already_insured: updateVehicle?.vehicle_already_insured,
            color: quotationData?.vehicle?.color,
            vehicle_color: quotationData?.vehicle?.vehicle_color,
          },
          quotationProposalData: {
            ...quotationData.quotationProposalData,
            under25: updateVehicle.some_driver_18_25_years_old,
          },
        });
        console.log(quotationData?.vehicle);
      } catch (error) {
        console.log(error.message);
      }

      const resp = await api({
        method: 'POST',
        url: `/proposal`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: API_TOKEN,
        },
        data: formData,
        json: true,
      });
      setProposalResponse(resp.data);
      toast.success('Proposta criada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingProposal(false);
      nextStep();
    } catch (err) {
      setLoadingProposal(false);

      toast.error(err?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } finally {
      setLoadingProposal(false);
    }
  };

  const verifyVehicle = async (vehicle) => {
    try {
      const resp = await api({
        method: 'POST',
        url: `/vehicle-accepted`,
        data: vehicle,
      });
      setvehicleAccepted(resp?.data);
      return resp;
    } catch (error) {}
  };

  const updateQuotationVehicle = async (id_quotation_proposal, data) => {
    await api({
      method: 'patch',
      url: `/quotation/vehicle/${id_quotation_proposal}`,
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: API_TOKEN,
      },
    });
  };

  const handleVerifyVehicleAuction = async () => {
    setLoadingVerifyVehicleAuction(true);
    let auction = false;
    try {
      const plate = quotationData.vehicle?.plate;
      const chassi = quotationData.vehicle?.chassi;
      if (plate) {
        const respAuction = await api({
          method: 'GET',
          url: `/verify-auction-plate/${plate.replace(/[^a-zA-Z0-9]+/g, '')}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: API_TOKEN,
          },
          params: { json: true },
        });
        auction = respAuction?.data?.indice > 0;
      } else {
        const respAuction = await api({
          method: 'GET',
          url: `/verify-auction-chassi/${chassi.replace(/[^a-zA-Z0-9]+/g, '')}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: API_TOKEN,
          },
          json: true,
        });
        auction = respAuction?.data?.indice > 0;
      }
      setQuotationData((prev) => ({ ...prev, vehicle: { ...prev.vehicle, auction } }));
      await updateQuotationVehicle(quotationData.quotationProposalData.quotationProposalId, { auction });
      if (auction) {
        setShowAuctionModal(true);
      } else {
        await handleProposal();
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        toastError(error?.response?.data?.error);
      } else {
        toastError('Não foi possível verificar dados do veiculo');
      }
      if (error?.response?.data?.reason === 'auction') {
        auction = true;
        setQuotationData((prev) => ({ ...prev, vehicle: { ...prev.vehicle, auction: true } }));
        await updateQuotationVehicle(quotationData.quotationProposalData.quotationProposalId, { auction });
      }
    }
    setLoadingVerifyVehicleAuction(false);
  };

  // useEffect(() => {
  //   handleQuotation();
  // }, [quotationData]);

  // useEffect(() => {
  //   if (loadedQuotation) {
  //     setQuotationData(JSON.parse(loadedQuotation?.request_metadata));
  //     setQuotationResponse(JSON.parse(loadedQuotation?.responde_metadata));
  //     verifyVehicle({ vehicle: JSON.parse(loadedQuotation?.responde_metadata)?.vehicle });
  //     setCurrentStep(5);
  //   }
  // }, []);

  useEffect(() => {
    if (quotation) {
      getQuotation(quotation);
    }
  }, [quotation]);

  return (
    <NewQuotationContainer>
      <Centralizer>
        <Title>
          <div>
            {/* <IconButton size="small" style={{ marginRight: 10 }} onClick={() => history.push('/comercial/minhas-cotacoes')}>
              <MdChevronLeft size={24} />
            </IconButton> */}
            Cotação
          </div>
          {/* {currentStep > 1 && (
            <Tooltip title="Reiniciar cotação">
              <IconButton size="small" style={{ marginRight: 10 }} onClick={restartQuotation}>
                <MdRestartAlt size={24} />
              </IconButton>
            </Tooltip>
          )} */}
        </Title>

        <div>
          {
            <Stepper
              goToStep={goToStep}
              steps={steps}
              currentStep={currentStep}
              previousStep={previousStep}
              quotationData={quotationData}
              quotationResponse={quotationResponse}
              proposalResponse={proposalResponse}
              vehicleAccepted={vehicleAccepted}
            />
          }
        </div>

        <div>
          {currentStep === 1 && (
            <ClientStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
            />
          )}
          {currentStep === 2 && (
            <AddressStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
            />
          )}
          {currentStep === 3 && (
            <PlateStep quotationData={quotationData} setQuotationData={setQuotationData} nextStep={nextStep} />
          )}
          {currentStep === 4 && (
            <VehicleModelStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
              vehicleAccepted={vehicleAccepted}
              setvehicleAccepted={setvehicleAccepted}
              verifyVehicle={verifyVehicle}
            />
          )}
          {currentStep === 5 && (
            <CoverageStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
              percentCommission={percentCommission}
              percentDiscount={percentDiscount}
              grievance_percent={sliderHandle.grievance_percent}
              discount_percent={sliderHandle.discount_percent}
              sliderHandle={sliderHandle}
              setSliderHandle={setSliderHandle}
              dataCoverage={dataCoverage}
              setPercentCommission={setPercentCommission}
              setPercentDiscount={setPercentDiscount}
              setDataCoverage={setDataCoverage}
              quotationResponse={quotationResponse}
              loadingQuotation={loadingQuotation}
              proposalResponse={proposalResponse}
              handleQuotation={handleQuotation}
            />
          )}
          {currentStep === 6 && (
            <ProposalStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
              quotationResponse={quotationResponse}
              dataCoverage={dataCoverage}
              percentCommission={percentCommission}
              percentDiscount={percentDiscount}
              sliderHandle={sliderHandle}
              grievance_percent={sliderHandle.grievance_percent}
              discount_percent={sliderHandle.discount_percent}
              loadingQuotation={loadingQuotation}
              restartQuotation={restartQuotation}
              handleProposal={handleProposal}
              loadingProposal={loadingProposal}
              proposalResponse={proposalResponse}
              verifyVehicleAuction={handleVerifyVehicleAuction}
              showAuctionModal={showAuctionModal}
              setShowAuctionModal={setShowAuctionModal}
              loadingVerifyVehicleAuction={loadingVerifyVehicleAuction}
            />
          )}
          {currentStep === 7 && (
            <EmissionStep
              quotationData={quotationData}
              setQuotationData={setQuotationData}
              nextStep={nextStep}
              previousStep={previousStep}
              quotationResponse={quotationResponse}
              restartQuotation={restartQuotation}
              proposalResponse={proposalResponse}
            />
          )}
        </div>
      </Centralizer>
    </NewQuotationContainer>
  );
};

export default NewQuotation;
